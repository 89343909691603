import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ContentGetListInvoice } from "../../constants/types";

type Props = {
    open: boolean;
    onClose: () => void;
    invoice: ContentGetListInvoice;
};

export default function ModalDetailWebstore({ open, onClose, invoice }: Props) {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            onClose={onClose}
            fullScreen={isPhoneScreen}
            fullWidth
            scroll="body"
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Invoice Webstore
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack
                    mb={2}
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Typography fontSize="1.125rem" fontWeight={700}>
                        {invoice.nomorInvoice}
                    </Typography>
                    <Chip label={invoice.statusWebstore[0].status} />
                </Stack>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        "& > .invoice-label": {
                            fontWeight: 500,
                        },
                    }}
                >
                    <Grid item xs={6} className="invoice-label">
                        Nama
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.namaPelanggan}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Provinsi
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.provinsi}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Kota
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.kota}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Kecamatan
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.kecamatan}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Kelurahan
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.kelurahan}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Alamat
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.pelangganWebstore?.alamatPelanggan}
                    </Grid>
                    <Grid item xs={6} className="invoice-label">
                        Metode Pembayaran
                    </Grid>
                    <Grid item xs={6}>
                        {invoice.metode ?? "COD"}
                    </Grid>
                    {invoice.metode && (
                        <>
                            <Grid item xs={6} className="invoice-label">
                                Nomor Rekening
                            </Grid>
                            <Grid item xs={6}>
                                {invoice.pelangganWebstore?.rekeningPelanggan}
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
