/* eslint-disable @typescript-eslint/no-unused-expressions */
// import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useMemo, useState } from "react";
import "chart.js/auto";
import "./Laporan.css";
import "../../components/FilterLaporan/FilterLaporan.css";
import id from "date-fns/locale/id";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import Spinner from "../../components/Spinner/Spinner";
import LogoUlo from "../../components/LogoUlo/LogoUlo";
import toRibuan from "../../utils/toRibuan";
import toRupiah from "../../utils/toRupiah";
import ReceiptIcon from "@mui/icons-material/Receipt";
import scrollToHash from "../../utils/scrollToHash";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PieChartIcon from "@mui/icons-material/PieChart";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalAlertPro from "../../components/ModalAlertPro/ModalAlertPro";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import useLaporanTotal from "../../services/queries/useLaporanTotal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useGrafikPenjualan from "../../services/queries/useGrafikPenjualan";
import CardLaporanKalkulasi from "../../components/CardLaporanKalkulasi/CardLaporanKalkulasi";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { Line } from "react-chartjs-2";
import { useAuth } from "../../context/authContext";
import { useTheme } from "@mui/material/styles";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";

import {
    ParamsGetLaporanLabaRugi,
    ParamsGetLaporanTotal,
    ParamsGetUkm,
    ParamsGrafikPenjualan,
    ParamsKesimpulan,
} from "../../constants/types";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TokoTidakAktif from "../../components/TokoTidakAktif/TokoTidakAktif";
import moment from "moment";
import "moment/locale/id";
import { BlobProvider } from "@react-pdf/renderer";
import PrintStrukKesimpulan from "../../components/PrintStrukKesimpulan/PrintStrukKesimpulan";
import useKesimpulan from "../../services/queries/useKesimpulan";
import PrintIcon from "@mui/icons-material/Print";
import useLaporanLabaRugi from "../../services/queries/useLaporanLabaRugi";
import CardDaftarInvoice from "../../components/CardLaporan/CardDaftarInvoice";
import CardMutasiStok from "../../components/CardLaporan/CardMutasiStok";
import CardStokTerjual from "../../components/CardLaporan/CardStokTerjual";
import CardPengeluaran from "../../components/CardLaporan/CardPengeluaran";
import CardLaporanLabaRugi from "../../components/CardLaporan/CardLaporanLabaRugi";
import Skeleton from "@mui/material/Skeleton";
import ErrorFetchView from "../../components/ErrorFetchView/ErrorFetchView";
import useTimeAlt from "../../services/queries/useTimeAlt";
import ModalUnduhLaporan from "../../components/ModalUnduhLaporan/ModalUnduhLaporan";

moment.locales();
moment.locale("id");

registerLocale("id", id);
setDefaultLocale("id");

type DateFilter = [Date | null, Date | null];

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Laporan = () => {
    const theme = useTheme();
    const { data: noWaAdmin } = useNoWaAdmin();
    const moment = require("moment");
    let now = moment().format("YYYY/MM/DD");
    var today = new Date();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [openTooltipTotalPendapatan, setOpenTooltipTotalPendapatan] =
        useState(false);
    const [openTooltipTotalTransaksi, setOpenTooltipTotalTransaksi] =
        useState(false);
    const [openTooltipProdukTerjual, setOpenTooltipProdukTerjual] =
        useState(false);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [isOpenModalUnduhLaporan, setIsOpenModalUnduhLaporan] =
        useState(false);

    const openModalUnduhLaporan = () => setIsOpenModalUnduhLaporan(true);
    const closeModalUnduhLaporan = () => setIsOpenModalUnduhLaporan(false);

    const [value, setValue] = useState(0);
    // const [showButton, setShowButton] = useState(false);

    const changeTab = (index: number) => {
        setValue(index);
        const tabsContainer = document.getElementById("tabs-container");
        tabsContainer?.scrollIntoView(true);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function subtractMonths(numOfMonths: number, date = new Date()) {
        date.setMonth(date.getMonth() - numOfMonths);

        return date;
    }

    function subtractYears(numOfYears: number, date = new Date()) {
        date.setFullYear(date.getFullYear() - numOfYears);

        return date;
    }

    const tooltipTotalPendapatan = `
    Total Pendapatan menunjukkan total keuntunganmu pada periode waktu tertentu.
    `;
    const tooltipTotalTransaksi = `
    Total Transaksi menunjukkan berapa banyak transaksi yang dilakukan pada periode waktu tertentu.
    `;
    const tooltipProdukTerjual = `
    Jumlah Stok Terjual menunjukkan jumlah barang yang terjual pada periode waktu tertentu.
    `;

    const { data: user, status: loadingUser } = useUser();
    const { ukmIdUser } = useAuth();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const initialParamsGrafikPenjualan = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsLaporanTotal = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tanggalAwal: now,
        tanggalAkhir: now,
    };

    const initialParamsLaporanLabaRugi = {
        ukmId: ukmIdUser && ukmIdUser[0],
        tglawal: now,
        tglakhir: now,
    };

    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;

    const [paramsUkm] = useState<ParamsGetUkm>(initialParamsUkm);
    const [paramsGrafikPenjualan, setParamsGarafikPenjualan] =
        useState<ParamsGrafikPenjualan>(initialParamsGrafikPenjualan);
    const [paramsLaporanTotal, setParamsLaporanTotal] =
        useState<ParamsGetLaporanTotal>(initialParamsLaporanTotal);
    const [paramsLaporanLabaRugi, setParamsLaporanLabaRugi] =
        React.useState<ParamsGetLaporanLabaRugi>(initialParamsLaporanLabaRugi);

    const { data: ukm } = useUkm(paramsUkm);
    const { data: time } = useTimeAlt();
    const {
        data: grafikPenjualan,
        isLoading: isLoadingGrafikPenjualan,
        refetch: refetchGrafikPenjualan,
        isError: isErrorGrafik,
    } = useGrafikPenjualan(paramsGrafikPenjualan);
    const {
        data: laporanTotal,
        isLoading: isLoadingLaporanTotal,
        refetch,
        isError: isErrorLaporanTotal,
    } = useLaporanTotal(paramsLaporanTotal);
    const {
        data: laporanLabaRugi,
        refetch: refetchLaporanLabaRugi,
        isLoading: isLoadingLaporanLabaRugi,
        isError: isErrorLaporanLabaRugi,
    } = useLaporanLabaRugi(paramsLaporanLabaRugi);

    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const initialParamsKesimpulan = {
        ukmId: undefined,
        tanggal: now,
        grupProduk: 1,
    };
    const [paramsKesimpulan, setParamsKesimpulan] = useState<ParamsKesimpulan>(
        initialParamsKesimpulan,
    );
    const { data: kesimpulan, refetch: refecthKesimpulan } =
        useKesimpulan(paramsKesimpulan);

    const handleConvertSatuan = (value: string) => {
        if (Number(value) >= 1000000000)
            return Number(value) / 1000000000 + "m";
        if (Number(value) >= 1000000) return Number(value) / 1000000 + "jt";
        if (Number(value) >= 1000) return Number(value) / 1000 + "rb";
        return String(value);
    };

    const options = {
        plugins: { legend: { display: false } },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: "index" as const,
            intersect: false,
        },
        stacked: false,
        locale: "id-ID",
        scales: {
            x: {
                display: true,
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                display: true,
                position: "left" as const,
                ticks: {
                    callback: function (value: any, index: any, values: any) {
                        return handleConvertSatuan(value);
                    },
                },
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Total Pendapatan",
                },
            },
            y1: {
                display: true,
                position: "right" as const,
                grid: {
                    drawOnChartArea: false,
                },
                gridLines: {
                    drawBorder: false,
                },
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Jumlah Transaksi",
                },
            },
        },
    };

    const data = {
        labels: grafikPenjualan?.labels,
        datasets: [
            {
                label: "Total Pendapatan",
                data: grafikPenjualan?.data.totalPendapatan,
                borderColor: "#45A779",
                backgroundColor: "#45A779",
                yAxisID: "y",
                tension: 0.5,
                pointRadius: 0,
            },
            {
                label: "Total Transaksi",
                data: grafikPenjualan?.data.totalTransaksi,
                borderColor: "#e8d43a",
                backgroundColor: "#e8d43a",
                yAxisID: "y1",
                tension: 0.5,
                pointRadius: 0,
            },
            // {
            //     label: "Stok Terjual",
            //     data: grafikPenjualan?.data.jumlahProdukTerjual,
            //     borderColor: "#3b5284",
            //     backgroundColor: "#3b5284",
            //     yAxisID: "y1",
            //     tension: 0.5,
            //     pointRadius: 0,
            // },
        ],
    };

    const handleChangePeriode = (event: SelectChangeEvent) => {
        if (event.target.value === "setahun") {
            setIsOpenModalAlertPro(true);
        } else {
            setDateRange([new Date(event.target.value), new Date()]);
            setParamsGarafikPenjualan((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsLaporanTotal((prev) => ({
                ...prev,
                tanggalAwal: String(event.target.value),
                tanggalAkhir: now,
                page: 1,
            }));
            setParamsKesimpulan((prev) => ({
                ...prev,
                tanggal: now,
                page: 1,
            }));
            setParamsLaporanLabaRugi((prev) => ({
                ...prev,
                tglawal: String(event.target.value),
                tglakhir: now,
                page: 1,
            }));
            console.log(event.target.value);
            refetch();
            refecthKesimpulan();
            refetchGrafikPenjualan();
            refetchLaporanLabaRugi();
        }
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsLaporanTotal((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsKesimpulan((prev) => ({
            ...prev,
            tanggal: tanggalAkhir,
            page: 1,
        }));
        setParamsGarafikPenjualan((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        setParamsLaporanLabaRugi((prev) => ({
            ...prev,
            tglawal: tanggalAwal,
            tglakhir: tanggalAkhir,
            page: 1,
        }));
        refetch();
        refecthKesimpulan();
        refetchLaporanLabaRugi();
    };

    useEffect(() => {
        if (ukmIdUser) {
            setParamsGarafikPenjualan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsLaporanTotal((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsKesimpulan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        scrollToHash();
    }, []);

    // useEffect(() => {
    //     window.addEventListener("scroll", () => {
    //         if (window.pageYOffset > 100) {
    //             setShowButton(true);
    //         } else {
    //             setShowButton(false);
    //         }
    //     });
    // }, []);

    // This function will scroll the window to the top
    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth", // for smoothly scrolling
    //     });
    // };

    const isSudahJatuhTempo = moment(time?.dateTime).isAfter(
        ukmData?.pembayaranBerikutnya,
    );

    const isError =
        isErrorGrafik || isErrorLaporanLabaRugi || isErrorLaporanTotal;

    if (loadingUser === "loading") {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    if (isError) {
        return <ErrorFetchView />;
    }

    if (user?.aksesLaporan === 0 || user?.aksesLaporan === null) {
        return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    }

    if (
        ukmData &&
        (ukmData?.isActive === 0 ||
            ukmData?.isActive === null ||
            ukmData?.lewatTempoPro === 1 ||
            isSudahJatuhTempo)
    ) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                bgcolor="#ffffff"
                gap={3}
                padding={2}
                sx={{
                    height: isTabletScreen
                        ? "calc(100vh - 64px)"
                        : isPhoneScreen
                        ? "calc(100vh - 56px)"
                        : "100vh",
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <LogoUlo width="100px" />
                    <Typography marginTop={2} textAlign="center">
                        Mohon maaf, ukm anda berstatus tidak aktif
                    </Typography>
                    {ukmData.isPro === 1 ? (
                        <Typography textAlign="center">
                            Anda tetap bisa melakukan unduh laporan.
                        </Typography>
                    ) : null}
                </Box>
                {ukmData.isPro === 1 ? (
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <DatePicker
                            className="range-filter"
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            // open={isOpenKalender}
                            onFocus={(e) => e.target.blur()}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                                if (update[1]) {
                                    handleChangeJangkaWaktu(
                                        moment(update[0]).format("YYYY/MM/DD"),
                                        moment(update[1]).format("YYYY/MM/DD"),
                                    );
                                }
                            }}
                            maxDate={today}
                            minDate={
                                ukmData?.isPro === 1
                                    ? subtractYears(1)
                                    : subtractMonths(3)
                            }
                            // showPreviousMonths={
                            //     isPhoneScreen || isTabletScreen ? false : true
                            // }
                            monthsShown={
                                isPhoneScreen || isTabletScreen ? 1 : 2
                            }
                            // inline
                            customInput={
                                <Stack
                                    display="flex"
                                    justifyContent="space-between"
                                    direction="row"
                                    width="100%"
                                    bgcolor="#FFFF"
                                    height="40px"
                                    minWidth="250px"
                                    maxWidth="250px"
                                >
                                    <Typography>
                                        {moment(
                                            paramsLaporanTotal.tanggalAwal,
                                        ).format("DD/MM/YYYY")}{" "}
                                        -{" "}
                                        {paramsLaporanTotal.tanggalAkhir &&
                                            moment(
                                                paramsLaporanTotal.tanggalAkhir,
                                            ).format("DD/MM/YYYY")}
                                    </Typography>
                                    <ArrowDropDownIcon
                                        sx={{
                                            marginRight: -3,
                                        }}
                                    />
                                </Stack>
                            }
                        />
                        <Button
                            disabled={user && !user.akses.laporan.includes(31)}
                            variant="contained"
                            onClick={() => {
                                openModalUnduhLaporan();
                            }}
                            startIcon={<CloudDownloadIcon />}
                            color="ulo"
                            sx={{ minWidth: "200px" }}
                        >
                            Unduh Laporan
                        </Button>
                    </Box>
                ) : (
                    <Button
                        variant="outlined"
                        startIcon={<WhatsAppIcon />}
                        target="_blank"
                        color="ulo"
                        href={`https://wa.me/${noWaAdmin?.nomor}?text=Kenapa%20toko%20saya%20yaitu%20${ukmData.namaToko}%20berstatus%20tidak%20aktif?`}
                    >
                        Hubungi Kami
                    </Button>
                )}
            </Box>
        );
    }

    return (
        <Box>
            <Box>
                <Stack
                    direction={{
                        xs: "column",
                        sm: "column",
                        md: "column",
                        lg: "row",
                    }}
                    display="flex"
                    justifyContent="space-between"
                    spacing={2}
                    marginY={2}
                >
                    <Typography id="laporan" variant="h6" fontWeight="bold">
                        Laporan
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        // justifyContent="space-between"
                        // justifyContent={isPhoneScreen || isTabletScreen ? (
                        //     "end"
                        // ) : (
                        //     "space-between"
                        // )}
                        justifyContent="end"
                        display="flex"
                    >
                        <Button
                            disabled={user && !user.akses.laporan.includes(33)}
                            variant="outlined"
                            startIcon={
                                isPhoneScreen || isTabletScreen ? undefined : (
                                    <ReceiptIcon fontSize="medium" />
                                )
                            }
                            size="small"
                            sx={{
                                width: "fit-content",
                                backgroundColor: "white",
                            }}
                            onClick={() => changeTab(0)}
                        >
                            {isPhoneScreen || isTabletScreen ? (
                                <ReceiptIcon fontSize="medium" />
                            ) : (
                                "Laporan Invoice"
                            )}
                        </Button>
                        <Button
                            disabled={user && !user.akses.laporan.includes(34)}
                            variant="outlined"
                            startIcon={
                                isPhoneScreen || isTabletScreen ? undefined : (
                                    <MoveToInboxIcon />
                                )
                            }
                            size="small"
                            sx={{
                                width: "fit-content",
                                backgroundColor: "white",
                            }}
                            onClick={() =>
                                changeTab(
                                    user && !user.akses.laporan.includes(33)
                                        ? 0
                                        : 1,
                                )
                            }
                        >
                            {isPhoneScreen || isTabletScreen ? (
                                <MoveToInboxIcon />
                            ) : (
                                "Laporan Mutasi"
                            )}
                        </Button>
                        <Button
                            disabled={user && !user.akses.laporan.includes(35)}
                            variant="outlined"
                            startIcon={
                                isPhoneScreen || isTabletScreen ? undefined : (
                                    <ReceiptIcon fontSize="medium" />
                                )
                            }
                            size="small"
                            sx={{
                                width: "fit-content",
                                backgroundColor: "white",
                            }}
                            onClick={() =>
                                changeTab(
                                    user &&
                                        !user.akses.laporan.includes(34) &&
                                        !user.akses.laporan.includes(33)
                                        ? 0
                                        : (user &&
                                              !user.akses.laporan.includes(
                                                  34,
                                              )) ||
                                          (user &&
                                              !user.akses.laporan.includes(33))
                                        ? 1
                                        : 2,
                                )
                            }
                        >
                            {isPhoneScreen || isTabletScreen ? (
                                <PieChartIcon fontSize="medium" />
                            ) : (
                                "Stok Terjual"
                            )}
                        </Button>
                        {/* <ButtonAnchor
                            to="daftarInvoice"
                            icon={<ReceiptIcon fontSize="medium" />}
                            buttonText="Laporan Invoice"
                        />
                        <ButtonAnchor
                            to="mutasiStok"
                            icon={<MoveToInboxIcon />}
                            buttonText="Laporan Mutasi"
                        />
                        <ButtonAnchor
                            to="#produkTerjual"
                            icon={<PieChartIcon />}
                            buttonText="Stok Terjual"
                        /> */}

                        <Button
                            disabled={user && !user.akses.laporan.includes(31)}
                            variant="contained"
                            onClick={() => {
                                console.log(ukmData);
                                if (ukmData?.isPro === 1) {
                                    // window.open(apiExportLaporan, "_blank");
                                    openModalUnduhLaporan();
                                } else {
                                    setIsOpenModalAlertPro(true);
                                }
                            }}
                            startIcon={
                                isPhoneScreen || isTabletScreen ? undefined : (
                                    <CloudDownloadIcon />
                                )
                            }
                            size="small"
                            sx={{ width: "fit-content" }}
                        >
                            <Stack direction="row" gap={1} alignItems="center">
                                {isPhoneScreen || isTabletScreen ? (
                                    <CloudDownloadIcon />
                                ) : (
                                    "Unduh Laporan"
                                )}
                                {ukmData?.isPro === 1 ? null : (
                                    <Chip
                                        label="PRO"
                                        size="small"
                                        sx={{
                                            backgroundColor: "#fff",
                                            color: "primary.main",
                                            borderRadius: 1,
                                        }}
                                    />
                                )}
                            </Stack>
                        </Button>

                        {ukmData && (
                            <BlobProvider
                                document={
                                    <PrintStrukKesimpulan
                                        namaToko={ukmData?.namaToko ?? "Toko"}
                                        alamatToko={
                                            ukmData?.alamatToko ?? "Toko"
                                        }
                                        telpToko={ukmData?.noTelp ?? "Toko"}
                                        gambarToko={
                                            ukmData?.gambarTokoBW === null
                                                ? undefined
                                                : ukmData?.gambarTokoBW
                                        }
                                        // tanggalTransaksi={nowK}
                                        tanggalTransaksi={moment(
                                            paramsKesimpulan.tanggal,
                                        ).format("DD MMM YYYY")}
                                        html={undefined}
                                        kesimpulan={kesimpulan ?? []}
                                    />
                                }
                            >
                                {({ blob, url, loading, error }) => {
                                    return (
                                        <LoadingButton
                                            disabled={
                                                user &&
                                                !user.akses.laporan.includes(36)
                                            }
                                            loading={loading}
                                            startIcon={
                                                isPhoneScreen ||
                                                isTabletScreen ? undefined : (
                                                    <PrintIcon />
                                                )
                                            }
                                            loadingPosition="center"
                                            size="small"
                                            variant="contained"
                                            href={url ?? ""}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {/* Print Tutup Buku */}
                                            {isPhoneScreen || isTabletScreen ? (
                                                <PrintIcon />
                                            ) : (
                                                "Print Tutup Buku"
                                            )}
                                        </LoadingButton>
                                    );
                                }}
                            </BlobProvider>
                        )}
                    </Stack>
                </Stack>
                <Box
                    display="grid"
                    gridTemplateColumns={isPhoneScreen ? "1fr" : "1fr 2fr"}
                    gap={2}
                    width={isPhoneScreen ? "100%" : "60%"}
                    marginBottom={3}
                >
                    <FormControl>
                        <Select
                            value={
                                paramsLaporanTotal.tanggalAwal === now ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(7, "days")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(1, "month")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(3, "months")
                                        .format("YYYY-MM-DD") ||
                                paramsLaporanTotal.tanggalAwal ===
                                    moment()
                                        .subtract(1, "years")
                                        .format("YYYY-MM-DD")
                                    ? paramsLaporanTotal.tanggalAwal
                                    : "Custom"
                            }
                            size="small"
                            onChange={handleChangePeriode}
                            sx={{ backgroundColor: "white" }}
                        >
                            <MenuItem value={now}>Hari ini</MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(7, "days")
                                    .format("YYYY-MM-DD")}
                            >
                                1 Minggu Terakhir
                            </MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(1, "month")
                                    .format("YYYY-MM-DD")}
                            >
                                1 Bulan Terakhir
                            </MenuItem>
                            <MenuItem
                                value={moment()
                                    .subtract(3, "months")
                                    .format("YYYY-MM-DD")}
                            >
                                3 Bulan Terakhir
                            </MenuItem>
                            <MenuItem
                                value={
                                    ukmData?.isPro === 1
                                        ? moment()
                                              .subtract(1, "years")
                                              .format("YYYY-MM-DD")
                                        : "setahun"
                                }
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    color={
                                        ukmData?.isPro === 1
                                            ? undefined
                                            : "#dddddd"
                                    }
                                >
                                    1 Tahun Terakhir
                                </Typography>
                                {ukmData?.isPro === 1 ? null : (
                                    <Chip label="PRO" size="small" />
                                )}
                            </MenuItem>
                            <MenuItem value={"Custom"} sx={{ display: "none" }}>
                                Custom
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Stack
                        direction="column"
                        position="relative"
                        sx={{
                            minWidth: "100%",
                            marginTop: 0,
                        }}
                    >
                        {/* <Button
                            variant="outlined"
                            onClick={() => setIsOpenKalender(!isOpenKalender)}
                            disableRipple
                            disableElevation
                            fullWidth
                            color="inherit"
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                                borderColor: "#c4c4c4",
                                justifyContent: "space-between",
                                fontSize: "16px",
                                fontWeight: 400,
                                "&:hover": {
                                    borderColor: "black",
                                    bgcolor: "transparent",
                                },
                            }}
                        >
                            {`${moment(paramsProdukTerjual.tanggalAwal).format(
                                "DD/MM/YYYY",
                            )} - ${
                                paramsProdukTerjual.tanggalAkhir &&
                                moment(paramsProdukTerjual.tanggalAkhir).format(
                                    "DD/MM/YYYY",
                                )
                            }`}
                        </Button> */}
                        {/* {isOpenKalender && ( */}
                        {/* <ClickAwayListener
                                 onClickAway={() => setIsOpenKalender(false)}
                             > */}
                        <DatePicker
                            className="range-filter"
                            dateFormat="dd/MM/yyyy"
                            disabledKeyboardNavigation
                            // open={isOpenKalender}
                            onFocus={(e) => e.target.blur()}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                                if (update[1]) {
                                    handleChangeJangkaWaktu(
                                        moment(update[0]).format("YYYY/MM/DD"),
                                        moment(update[1]).format("YYYY/MM/DD"),
                                    );
                                }
                            }}
                            maxDate={today}
                            minDate={
                                ukmData?.isPro === 1
                                    ? subtractYears(1)
                                    : subtractMonths(3)
                            }
                            // showPreviousMonths={
                            //     isPhoneScreen || isTabletScreen ? false : true
                            // }
                            monthsShown={
                                isPhoneScreen || isTabletScreen ? 1 : 2
                            }
                            // inline
                            customInput={
                                <Stack
                                    display="flex"
                                    justifyContent="space-between"
                                    direction="row"
                                    width="100%"
                                    bgcolor="#FFFF"
                                    height="40px"
                                >
                                    <Typography>
                                        {moment(
                                            paramsLaporanTotal.tanggalAwal,
                                        ).format("DD/MM/YYYY")}{" "}
                                        -{" "}
                                        {paramsLaporanTotal.tanggalAkhir &&
                                            moment(
                                                paramsLaporanTotal.tanggalAkhir,
                                            ).format("DD/MM/YYYY")}
                                    </Typography>
                                    <ArrowDropDownIcon
                                        sx={{
                                            marginRight: -3,
                                        }}
                                    />
                                </Stack>
                            }
                        />
                        {/* </ClickAwayListener> */}
                        {/* )} */}
                    </Stack>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Total Pendapatan"
                            onClickAway={() =>
                                setOpenTooltipTotalPendapatan(false)
                            }
                            isLoading={isLoadingLaporanTotal}
                            tooltipTitle={tooltipTotalPendapatan}
                            onClose={() => setOpenTooltipTotalPendapatan(false)}
                            isOpenTooltip={openTooltipTotalPendapatan}
                            onClickTooltip={() =>
                                setOpenTooltipTotalPendapatan((prev) => !prev)
                            }
                            isiCard={toRupiah(
                                Number(laporanTotal?.totalPendapatan),
                            )}
                            icon={
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    fontWeight="bold"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    Rp
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Total Transaksi"
                            onClickAway={() =>
                                setOpenTooltipTotalTransaksi(false)
                            }
                            isLoading={isLoadingLaporanTotal}
                            tooltipTitle={tooltipTotalTransaksi}
                            onClose={() => setOpenTooltipTotalTransaksi(false)}
                            isOpenTooltip={openTooltipTotalTransaksi}
                            onClickTooltip={() =>
                                setOpenTooltipTotalTransaksi((prev) => !prev)
                            }
                            isiCard={`${toRibuan(
                                Number(laporanTotal?.totalTransaksi),
                            )} Transaksi`}
                            icon={
                                <InsertChartIcon
                                    sx={{
                                        color: "primary.main",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Jumlah Stok Terjual"
                            onClickAway={() =>
                                setOpenTooltipProdukTerjual(false)
                            }
                            isLoading={isLoadingLaporanTotal}
                            tooltipTitle={tooltipProdukTerjual}
                            onClose={() => setOpenTooltipProdukTerjual(false)}
                            isOpenTooltip={openTooltipProdukTerjual}
                            onClickTooltip={() =>
                                setOpenTooltipProdukTerjual((prev) => !prev)
                            }
                            isiCard={`${toRibuan(
                                Number(laporanTotal?.jumlahStokTerjual),
                            )} Stok`}
                            icon={
                                <PieChartIcon
                                    sx={{
                                        color: "primary.main",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Laba Kotor"
                            onClickAway={() => {
                                return;
                            }}
                            tooltipTitle=""
                            onClose={() => {
                                return;
                            }}
                            isOpenTooltip={false}
                            onClickTooltip={() => {
                                return;
                            }}
                            isiCard={`${toRupiah(
                                Number(
                                    laporanLabaRugi?.content["Gross Profit"],
                                ),
                            )}`}
                            isLoading={isLoadingLaporanLabaRugi}
                            icon={
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    fontWeight="bold"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    Rp
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Total Pengeluaran"
                            onClickAway={() => {
                                return;
                            }}
                            tooltipTitle=""
                            onClose={() => {
                                return;
                            }}
                            isOpenTooltip={false}
                            onClickTooltip={() => {
                                return;
                            }}
                            isiCard={`${toRupiah(
                                Number(
                                    laporanLabaRugi?.content[
                                        "Total dari Operational Expense"
                                    ],
                                ),
                            )}`}
                            isLoading={isLoadingLaporanLabaRugi}
                            icon={
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    fontWeight="bold"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    Rp
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardLaporanKalkulasi
                            namaCard="Laba Bersih (Rugi)"
                            onClickAway={() => {
                                return;
                            }}
                            tooltipTitle=""
                            onClose={() => {
                                return;
                            }}
                            isOpenTooltip={false}
                            onClickTooltip={() => {
                                return;
                            }}
                            isiCard={
                                laporanLabaRugi
                                    ? laporanLabaRugi.content[
                                          "Profit (Loss)"
                                      ] >= 0
                                        ? toRibuan(
                                              String(
                                                  laporanLabaRugi?.content[
                                                      "Profit (Loss)"
                                                  ],
                                              ),
                                          )
                                        : `( ${toRibuan(
                                              String(
                                                  laporanLabaRugi?.content[
                                                      "Profit (Loss)"
                                                  ] * -1,
                                              ),
                                          )} )`
                                    : ""
                            }
                            isLoading={isLoadingLaporanLabaRugi}
                            icon={
                                <Typography
                                    variant="h6"
                                    color="primary"
                                    fontWeight="bold"
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    Rp
                                </Typography>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid mt={3}>
                    <Box
                        sx={{
                            backgroundColor: "#FFFFFF",
                            border: 1,
                            borderColor: "#d4d4d4",
                            borderRadius: 1,
                            width: "100%",
                            padding: { xs: 1, lg: 2 },
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                            }}
                        >
                            {isLoadingGrafikPenjualan ? (
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={300}
                                />
                            ) : (
                                <Line
                                    data={data}
                                    options={options}
                                    width="100%"
                                    height="300"
                                />
                            )}
                        </Box>
                        <Stack
                            marginTop={1}
                            direction="row"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack direction="row">
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: "#45A779",
                                    }}
                                ></Box>
                                <Typography marginLeft={1} variant="subtitle2">
                                    Total Pendapatan
                                </Typography>
                            </Stack>
                            <Stack direction="row">
                                <Box
                                    sx={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: "#e8d43a",
                                    }}
                                ></Box>
                                <Typography marginLeft={1} variant="subtitle2">
                                    Jumlah Transaksi
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <Box marginTop={3}>
                    <Box id="tabs-container">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="basic tabs example"
                            sx={{
                                button: {
                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                                },
                                "button:first-child": {
                                    borderTopLeftRadius: "4px",
                                },
                                "button:last-child": {
                                    borderRight:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                    borderTopRightRadius: "4px",
                                },
                            }}
                        >
                            {user && user.akses.laporan.includes(33) && (
                                <Tab label="Invoice" {...a11yProps(0)} />
                            )}

                            {user && user.akses.laporan.includes(34) && (
                                <Tab label="Mutasi" {...a11yProps(1)} />
                            )}

                            {user && user.akses.laporan.includes(35) && (
                                <Tab label="Stok Terjual" {...a11yProps(2)} />
                            )}

                            {user && user.akses.keuangan.includes(71) && (
                                <Tab label="Pengeluaran" {...a11yProps(3)} />
                            )}

                            {user && user.akses.keuangan.includes(72) && (
                                <Tab label="Laba Rugi" {...a11yProps(4)} />
                            )}
                        </Tabs>
                    </Box>

                    {user && user.akses.laporan.includes(33) && (
                        <TabPanel value={value} index={0}>
                            <CardDaftarInvoice
                                tanggalAwal={paramsLaporanTotal.tanggalAwal}
                                tanggalAkhir={paramsLaporanTotal.tanggalAkhir}
                            />
                        </TabPanel>
                    )}

                    {user && user.akses.laporan.includes(34) && (
                        <TabPanel
                            value={value}
                            index={
                                user && !user.akses.laporan.includes(33) ? 0 : 1
                            }
                        >
                            <CardMutasiStok
                                tanggalAwal={paramsLaporanTotal.tanggalAwal}
                                tanggalAkhir={paramsLaporanTotal.tanggalAkhir}
                            />
                        </TabPanel>
                    )}

                    {user && user.akses.laporan.includes(35) && (
                        <TabPanel
                            value={value}
                            index={
                                user &&
                                !user.akses.laporan.includes(34) &&
                                !user.akses.laporan.includes(33)
                                    ? 0
                                    : (user &&
                                          !user.akses.laporan.includes(34)) ||
                                      (user && !user.akses.laporan.includes(33))
                                    ? 1
                                    : 2
                            }
                        >
                            <CardStokTerjual
                                tanggalAwal={paramsLaporanTotal.tanggalAwal}
                                tanggalAkhir={paramsLaporanTotal.tanggalAkhir}
                            />
                        </TabPanel>
                    )}

                    {user && user.akses.keuangan.includes(71) && (
                        <TabPanel
                            value={value}
                            index={
                                user &&
                                !user.akses.laporan.includes(33) &&
                                !user.akses.laporan.includes(34) &&
                                !user.akses.laporan.includes(35)
                                    ? 0
                                    : (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(34)) ||
                                      (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(35)) ||
                                      (!user.akses.laporan.includes(34) &&
                                          !user.akses.laporan.includes(35))
                                    ? 1
                                    : !user.akses.laporan.includes(33) ||
                                      !user.akses.laporan.includes(34) ||
                                      !user.akses.laporan.includes(35)
                                    ? 2
                                    : 3
                            }
                        >
                            <CardPengeluaran
                                tanggalAwal={paramsLaporanTotal.tanggalAwal}
                                tanggalAkhir={paramsLaporanTotal.tanggalAkhir}
                            />
                        </TabPanel>
                    )}

                    {user && user.akses.keuangan.includes(72) && (
                        <TabPanel
                            value={value}
                            index={
                                user &&
                                !user.akses.laporan.includes(33) &&
                                !user.akses.laporan.includes(34) &&
                                !user.akses.laporan.includes(35) &&
                                !user.akses.keuangan.includes(71)
                                    ? 0
                                    : (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(34) &&
                                          !user.akses.laporan.includes(35)) ||
                                      (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(34) &&
                                          !user.akses.keuangan.includes(71)) ||
                                      (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(35) &&
                                          !user.akses.keuangan.includes(71)) ||
                                      (!user.akses.laporan.includes(34) &&
                                          !user.akses.laporan.includes(35) &&
                                          !user.akses.keuangan.includes(71))
                                    ? 1
                                    : (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(34)) ||
                                      (!user.akses.laporan.includes(33) &&
                                          !user.akses.laporan.includes(35)) ||
                                      (!user.akses.laporan.includes(33) &&
                                          !user.akses.keuangan.includes(71)) ||
                                      (!user.akses.laporan.includes(34) &&
                                          !user.akses.laporan.includes(35)) ||
                                      (!user.akses.laporan.includes(35) &&
                                          !user.akses.keuangan.includes(71))
                                    ? 2
                                    : !user.akses.laporan.includes(33) ||
                                      !user.akses.laporan.includes(34) ||
                                      !user.akses.laporan.includes(35) ||
                                      !user.akses.keuangan.includes(71)
                                    ? 3
                                    : 4
                            }
                        >
                            <CardLaporanLabaRugi
                                tanggalAwal={paramsLaporanTotal.tanggalAwal}
                                tanggalAkhir={paramsLaporanTotal.tanggalAkhir}
                            />
                        </TabPanel>
                    )}
                </Box>
                {/* <Box
                    display={showButton ? "block" : "none"}
                    position="fixed"
                    right={30}
                    bottom={30}
                >
                    <Fab color="primary" onClick={scrollToTop}>
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Box> */}
                {ukmData && (
                    <ModalAlertPro
                        isOpenModalAlertPro={isOpenModalAlertPro}
                        closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                        dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                    />
                )}
                <ModalUnduhLaporan
                    open={isOpenModalUnduhLaporan}
                    onClose={closeModalUnduhLaporan}
                    paramsUnduhLaporan={paramsLaporanTotal}
                    namaUkm={ukmData?.namaToko}
                />
            </Box>
        </Box>
    );
};

export default Laporan;
